<template>
  <div v-loading="loading" element-loading-text="请求中">
    <div class="start">
      <span class="demonstration">选择日期:</span>
      <el-date-picker size="small" class="date" v-model="data" type="date" placeholder="查询日期" value-format="yyyyMMdd">
      </el-date-picker>
      <el-button style="margin-left:20px;" type="primary" size="small" @click="commitLog()">
        <i class="el-icon-search el-icon"></i>查询
      </el-button>
    </div>
    <div class="res">
      <el-table :border="true" :max-height='screenHeight' :data="tableData" style="width: 100%" :cell-style="{'text-align':'left !important' }" :header-cell-style="{'text-align':'left !important'}">
        <el-table-column prop="time" label="日期" width="200">
        </el-table-column>
        <el-table-column prop="message" label="日志信息">
        </el-table-column>

      </el-table>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      loading: false,
      data: '',
      tableData: [],
      screenHeight: window.innerHeight - 220
    }
  },
  watch: {

  },
  created() {
    window.addEventListener('resize', this.getHeight)
  },
  mounted() {
    this.ws.addCallback('logAck', (res) => {
      this.loading = false
      this.on_logAck(res)
    })

  },
  methods: {
    commitLog() {
      if (this.data == '' || this.data == null) {
        this.common.cResultNo(this, '请选择查询日期');
        return
      }
      this.loading = true
      let json = {
        "cmd": "log",
        "startDate": this.data,
        "stopDate": this.data,
      }
      this.ws.send(json);
    },
    on_logAck(res) {
      if (res.error != 0 || res.cmd != "logAck") {
        return
      }
      this.tableData = res.items
    },
    getHeight() {
      this.screenHeight = window.innerHeight - 220
    }
  }
}
</script>

<style>
.demonstration {
  padding: 10px;
}
.date {
  width: 160px !important;
  font-size: 12px !important;
}
.res {
  margin-top: 20px;
}
</style>